export const environment = {
  production: true,
  envLabel: 'PRODUCTION',
  encryptedKey: 'U2FsdGVkX19IBL7Ybtgs8zTDq4RLiFFUprpfcxIV9eI=',
  version: '1.1.1',
  clientEndpoint: 'https://dvtime.se/',
  baseUrl: 'https://api-prod.dvtime.se/',
  hostName: 'https://api-prod.dvtime.se',
  restAPI: '/api/v1/',
  appKey: 'dvtimenextgen',
  translateURL: 'https://api.datavaxt.se',
  appsAPI: 'https://api.datavaxt.se/api/v1/applikation/se',
  GTM_ID: 'GTM-NW9FKWK3',
};
