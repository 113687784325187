export const LOCAL_STORAGE_CONSTANT = {
  CURRENT_LANGUAGE_STATE_KEY: 'currentLang',
  LOGIN_TOKEN: 'lt',
  USER_DATA: 'ud',
  USER_LIST: 'ul',
  RECENT_REPORTS: 'rr',
  BIOGAS_STATUS: 'bs',
  PROMPT_BEEN_SHOWN: 'pbs',
  REPORT_PERIOD: 'rp',
};
