import { Injectable, inject } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private $gaService = inject(GoogleAnalyticsService);

  public trackPageView(path: string, title?: string, location?: string): void {
    this.$gaService.pageView(path, title, location);
  }

  public trackEvent(
    eventData: {
      action: string;
      category?: string;
      label?: string;
    },
    interaction?: boolean,
    value?: number,
    options?: unknown,
  ): void {
    const { action, category, label } = eventData;
    this.$gaService.event(action, category, label, value, interaction, options);
  }

  public setUserId(userId: string | number): void {
    this.$gaService.gtag('set', { user_id: userId });
  }

  public setUserProperty(propertyName: string, value: string): void {
    this.$gaService.gtag('set', { [propertyName]: value });
  }
}
