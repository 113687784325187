/* eslint-disable no-magic-numbers */
export const APP_CONSTANTS = {
  REDIRECT_URL: 'redirect_url',
  SUPPORT_EMAIL: 'support.gps@datavaxt.se',
  LOGIN_DATAVXT: 'oauth/login/dvtime',
  LOGOUT_DATAVXT: 'oauth/logout/dvtime',
  LOGIN_GOOGLE: 'oauth/login/google',
  KNOWLEDGE_BASE: 'https://support.datavaxt.com/product_category/dvtime/',
  MAIL_TO_DATAVXT: 'mailto:info@datavaxt.se',
  register: 'registerToken',
  BIOGAS_PRODUCT_NAME: 'Nettovikt',
  MOBILE_DEVICE_WIDTH: 768,
  VACATION_HOUR: 8,
};

export const SHORTCUTS = {
  KNOWLEDGE_BASE: 'https://support.datavaxt.com',
  REMOTE_ACCESS: 'https://get.teamviewer.com/dh2m3eg',
  EXPORT: 'https://export.datavaxt.se',
  POLICY: 'https://cdn.datavaxt.se/agreements/integritetspolicy.pdf',
};

export const REGEX_CONSTANTS = {
  EMAIL_REGEX:
    // eslint-disable-next-line max-len
    /^[\p{L}0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[\p{L}0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[_\p{L}0-9][-_\p{L}0-9]*\.)*(?:[\p{L}0-9][-\p{L}0-9]{0,62})\.(?:(?:[a-z]{2}\.)?[a-z]{2,})$/iu,
  PHONE_REGEX: /^(\+46\s?\(?0?\)?|0)?7[0236][\s-]?(\d[\s-]?){6}\d$/,
};

export enum MessageType {
  info = 'info',
  error = 'error',
  warning = 'warning',
  success = 'success',
}

export const LANGUAGE_CONSTANTS = {
  en: 'en',
  sv: 'sv',
  no: 'no', // Norwegian-Norsk
  nb: 'nb', // Norwegian Bokmål
  fi: 'fi', // Finnish
};

export const DEFAULT_LANGUAGE = LANGUAGE_CONSTANTS.sv;

export const PAGE_SIZE = [10, 25, 50, 100];

export enum ErrorCode {
  badRequest = 400,
  unauthorized = 401,
  notFound = 404,
  notAcceptable = 406,
  internalServer = 500,
}

export const LANGUAGE_LIST = [
  { value: 'en', label: 'English' },
  { value: 'sv', label: 'Svenska' },
  { value: 'no', label: 'Norsk' },
  { value: 'fi', label: 'Suomi' },
];

export enum HttpMethod {
  post = 'POST',
  get = 'GET',
}

export enum RegexType {
  decimal = 'decimal',
  integer = 'integer',
}

export enum ActivityStatus {
  active,
  inactive,
  invited,
}

export const ACTIVITY_STATUS = [
  { value: ActivityStatus.active, label: 'Active', isSelected: true },
  { value: ActivityStatus.inactive, label: 'Inactive', isSelected: false },
];

export const CLIENTURL = ':clientUrl';
