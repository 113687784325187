export const PRODUCT_API_ROUTES = {
  GET_PRODUCTS: 'products',
  GET_GROUPS: 'products/groups',
  GET_UNITS: 'products/units',
};

export const PRODUCTS_STATUS_API_ROUTES = {
  GET_PRODUCTS_STATUS: 'products/status',
  GET_ALL_PRODUCTS_STATUS: 'products/status?showInactive=true',
};

export const CUSTOMER_API_ROUTES = {
  GET_CUSTOMERS: 'customer',
};

export const PRODUCTS_TEMPLATES_API_ROUTES = {
  PRODUCTS_TEMPLATES: 'productstemplates',
};

export const TIME_MANAGEMENT_API_ROUTES = {
  GET_TIME_GROUPS: 'time/groups',
};

export const OVERTIME_TEMPLATES_API_ROUTES = {
  GET_TIME_TEMPLATES: 'days/templates',
};

export const ICON_API_ROUTES = {
  GET_ICONS: 'icons',
};

export const OVERVIEW_API_ROUTES = {
  GET_OVERVIEW: 'overview',
  PRODUCT_ENTRY: 'products/entrys',
  ADD_PRODUCT_ENTRY: 'products/entrys/muliple',
  MILEAGE: 'mileage',
  ALLOWANCE: 'traktamente',
  MULTIPLE: 'muliple',
  TIME: 'days',
  GET_TIMEBANK_SUMMARY: 'clientUsers/totaltimebank',
  FILTER_LIST: 'products/entries/filters',
  FILTERED_PRODUCT_ENTRY: 'products/entries',
};

export const USERRIGHTS_API_ROUTES = {
  GET_ROLES: 'authrole',
};

export const USERS_API_ROUTES = {
  GET_USERS: 'clientUsers',
  GET_SUMMARY: 'timeSheet',
  LOCK_SUMMARY: 'lockmonth',
  UNLOCK_SUMMARY: 'unlockmonth',
  USER_SUMMARY: 'days',
};

export const USER_API_ROUTES = {
  GET_CLIENTS: 'clients',
  GET_ME: 'me',
  GET_HOURS: 'hours',
};

export const BIOGAS_API_ROUTES = {
  GET_BIOGAS: 'biogas',
  GET_ACTIVE: 'active',
  GET_PRODUCT: 'product',
};

export const REPORT_API_ROUTES = {
  GET_REPORTS: 'report',
  GET_REPORT_LIST: 'reportList',
  GET_REPORT_PDF: 'reportpdf',
  GET_REPORT_XLS: 'reportxls',
  GET_FAV: 'favorite',
};

export const ASSIGNMENT_API_ROUTES = {
  GET_ASSIGNMENTS: 'todo',
};
