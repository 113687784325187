export const GA_EVENTS = {
  ADD_PRODUCT: {
    action: 'add_product',
    category: 'product',
    label: 'Product added',
  },
  EDIT_PRODUCT: {
    action: 'edit_product',
    category: 'product',
    label: 'Product edited',
  },
  DELETE_PRODUCT: {
    action: 'delete_product',
    category: 'product',
    label: 'Product deleted',
  },
  ADD_MILEAGE: {
    action: 'add_mileage',
    category: 'mileage',
    label: 'Mileage added',
  },
  EDIT_MILEAGE: {
    action: 'edit_mileage',
    category: 'mileage',
    label: 'Mileage edited',
  },
  DELETE_MILEAGE: {
    action: 'delete_mileage',
    category: 'mileage',
    label: 'Mileage deleted',
  },
  ADD_ALLOWANCE: {
    action: 'add_allowance',
    category: 'allowance',
    label: 'Allowance added',
  },
  EDIT_ALLOWANCE: {
    action: 'edit_allowance',
    category: 'allowance',
    label: 'Allowance edited',
  },
  DELETE_ALLOWANCE: {
    action: 'delete_allowance',
    category: 'allowance',
    label: 'Allowance deleted',
  },
  ADD_TIME: {
    action: 'add_time',
    category: 'time',
    label: 'Time added',
  },
  EDIT_TIME: {
    action: 'edit_time',
    category: 'time',
    label: 'Time edited',
  },
  DELETE_TIME: {
    action: 'delete_time',
    category: 'time',
    label: 'Time deleted',
  },
  ADD_BIOGAS: {
    action: 'add_biogas',
    category: 'biogas',
    label: 'Biogas added',
  },
  EDIT_BIOGAS: {
    action: 'edit_biogas',
    category: 'biogas',
    label: 'Biogas edited',
  },
  DELETE_BIOGAS: {
    action: 'delete_biogas',
    category: 'biogas',
    label: 'Biogas deleted',
  },
  ADD_CUSTOMER: {
    action: 'add_customer',
    category: 'customer',
    label: 'Customer added',
  },
  EDIT_CUSTOMER: {
    action: 'edit_customer',
    category: 'customer',
    label: 'Customer edited',
  },
  DELETE_CUSTOMER: {
    action: 'delete_customer',
    category: 'customer',
    label: 'Customer deleted',
  },
  IMPORT_CUSTOMER: {
    action: 'import_customer',
    category: 'customer',
    label: 'Customer imported',
  },
  IMPORT_CUSTOMER_ERROR: {
    action: 'import_customer_error',
    category: 'customer',
    label: 'Error: Customer import failed',
  },
  VIEW_REPORT: {
    action: 'view_report',
    category: 'report',
    label: '',
  },
  DOWNLOAD_REPORT: {
    action: 'download_report',
    category: 'report',
    label: '',
  },
  LANGUAGE_CHANGE: {
    action: 'language_change',
    category: 'language',
    label: '',
  },
  LOGIN: {
    action: 'login',
    category: 'authentication',
    label: 'Login successful',
  },
  LOGOUT: {
    action: 'logout',
    category: 'authentication',
    label: 'Logout successful',
  },
  HELP: {
    action: 'help',
    category: 'help',
    label: 'Help',
  },
};
